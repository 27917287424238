import React, { Fragment } from "react"
import Layout from "../components/layout"
import SignUpForm from "../components/sign-up-form/SignUpForm"
import LayoutSimple from "../components/layoutSimple"
import SEO from "../components/seo"

const SignUpAsDesigner = () => {
  return (
    <Fragment>
      <SignUpForm type={"designer"} />
    </Fragment>
  )
}

export default () => (
  <LayoutSimple>
    <SEO
      pageTitle="Sign Up as Designer"
      description="Sign Up as a travel designer on voyayge"
    />
    <SignUpAsDesigner />
  </LayoutSimple>
)
